import {Component} from 'react';
import MissMeesh from './img/miss-meesh-full.jpg';
import OnlyFans from './svg/onlyfans.svg';
import Instagram from './svg/Instagram.svg';
import Snapchat from './svg/snapchat.svg';
import CreditCards from './img/credit-cards.png';

class App extends Component {
	componentDidMount(){
		
	}

	/**
	 * React Render
	 * @return {React.ComponentElement}
	 */
	render() {
		return <div className="content-area">
				<img style={{
					borderRadius: '5rem',
					width: '100%',
					maxWidth: 250
				}} src={MissMeesh} alt="The women herself, Miss Meesh" />
			<h1>Miss Meesh</h1>
			<div>
				<form action="https://www.paypal.com/donate" method="post" target="_top">
					<input type="hidden" name="hosted_button_id" value="BXJ3KT86ESW3S" />
					<button type="submit" style={{
						display: 'block',
						margin: 'auto',
						borderRadius: 24,
						border: 0,
						backgroundColor: '#a460e9',
						padding: '.5rem 1rem',
						color: '#FFF',
						fontSize: 24,
						fontWeight: 900,
						marginBottom: '.5rem',
					}}>Donate / TIP</button>
					<img src={CreditCards} style={{
						width: '100%',
					}} alt="We accept card from all major provider" />
				</form>
			</div>
			<ul className="menu">
				<li>
					<a href="https://www.onlyfans.com/missmeeesh" className="btn">
						<img src={OnlyFans} alt="OnlyFans MissMeeesh" style={{
							maxHeight: 64
						}} />
					</a>
				</li>
				<li>
					<a href="https://snapchat.com/add/missmeeesh" className="btn">
					<img src={Snapchat} style={{
						maxWidth:64,
						marginRight: '.5rem'
					}} alt="MissMeeesh on Snapchat" />
					Snapchat
					</a>
				</li>
				<li>
					<a href="https://instagram.com/missmeeessh" className="btn">
					<img src={Instagram} alt="MissMeeessh Instagram" style={{
						maxWidth: 64,
						marginRight: '.5rem'
					}} /> Instagram</a>
				</li>
			</ul>
		</div>;
	}
}

export default App;
